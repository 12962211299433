<template>
  <div class="page template-1">
    <header class="page-header container">
      <h1>{{ title }}</h1>
    </header>
    <!-- / Page Header -->
    <b-container class="page-body">
      <b-row>
        <b-col sm="6" offset="6" class="search-col">
          <search-form :i18n="translations.components" />
        </b-col>
      </b-row>
      <b-row class="filter-row">
        <b-col sm="12" class="filter-col mt-5">
          <h5>{{ translations.tcSearchResults }}</h5>
          <div class="filters">
            <span id="filters-label" class="mr-3">{{ translations.tcFilters }}</span>
            <b-form-checkbox-group id="cbFilters" v-model="selectedFilters" name="cbFilters">
              <b-form-checkbox value="Document">{{ translations.tcDocument }}</b-form-checkbox>
              <b-form-checkbox value="Image">{{ translations.tcImage }}</b-form-checkbox>
              <b-form-checkbox value="Link">{{ translations.tcLink }}</b-form-checkbox>
              <b-form-checkbox value="View">{{ translations.tcPage }}</b-form-checkbox>
              <b-form-checkbox value="Video">{{ translations.tcVideo }}</b-form-checkbox>
            </b-form-checkbox-group>
            <div id="dpContainer" class="dp-container">
              <div id="dpStart" class="dp-startend">
                <b-form-datepicker id="startDate" v-model="selectedDateRangeStart" class="mb-2 contact_date"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="prefCulture"
                  :label-help="translations.tcCalHelp" :label-no-date-selected="translations.tcDatePickerPlaceholder"
                  :placeholder="translations.tcStartDate" :min="minimumDate" :max="new Date()" size="sm" reset-button
                  :label-reset-button="translations.tcReset" :show-decade-nav="showDecadeNav" :hide-header="hideHeader">
                  <template #button-content style="padding: 12px">
                    <img src="@/assets/svgs/iCalendar.svg" class="cal-icon" alt="calendar icon" />
                  </template>
                </b-form-datepicker>
              </div>
              <div id="dpEnd" class="dp-startend">
                <b-form-datepicker id="endDate" v-model="selectedDateRangeEnd" class="mb-2 contact_date"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="prefCulture"
                  :label-help="translations.tcCalHelp" :label-no-date-selected="translations.tcDatePickerPlaceholder"
                  :placeholder="translations.tcEndDate" :min="minimumDate" :max="new Date()" size="sm" reset-button
                  :label-reset-button="translations.tcReset" :show-decade-nav="showDecadeNav" :hide-header="hideHeader">
                  <template #button-content style="padding: 12px">
                    <img src="@/assets/svgs/iCalendar.svg" class="cal-icon" alt="calendar icon" />
                  </template>
                </b-form-datepicker>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row class="result-row">
        <b-col sm="12" class="result-col mt-5">
          <data-table :title="null" :tooltip="tooltip" :fields="dataTableFields" :items="filteredResults"
            :i18n="translations.components" @video_click="handleVideoClick($event)"
            @topic_click="handleTopicClick($event)"></data-table>
        </b-col>
      </b-row>
    </b-container>
    <footer class="page-footer"></footer>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import dataTable from '@/components/DataTableMRP.vue'
import searchForm from './SearchForm.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'mrp-search',
  mixins: [translationMixin],
  data() {
    return {
      showDecadeNav: true,
      hideHeader: true,
      translations: {},
      tooltip: 'Testing',
      search: '',
      selectedFilters: [],
      localSearchField: '',
      dataTableFields: [
        {
          key: 'ThumbnailId',
          label: 'Type', // 'Type'
          sortable: true,
          tdClass: 'mrpSearchVideoIconClass',
        },
        {
          key: 'Title',
          label: 'Title', // 'Title'
          sortable: true,
        },
      ],
      selectedDateRangeStart: null,
      selectedDateRangeEnd: null,
    }
  },
  components: {
    SearchForm: searchForm,
    DataTable: dataTable,
  },
  methods: {
    ...mapActions({
      loadMRPProgramInfo: 'mrp/loadMRPProgramInfo',
      loadMRPPrograms: 'mrp/loadMRPPrograms',
      loadMRPProgramSearchResults: 'mrp/loadMRPProgramSearchResults',
      setLoadingStatus: 'menu/setLoadingStatus',
      setMRPProgramId: 'mrp/setMRPProgramId',
      setMRPSearchVideoId: 'mrp/setMRPSearchVideoId',
      setMRPSelectedCategory: 'mrp/setMRPSelectedCategory',
      setMRPSelectedTopicId: 'mrp/setMRPSelectedTopicId',
      setMRPSelectedVideoId: 'mrp/setMRPSelectedVideoId'
    }),
    createTitle(code) {
      return {
        ax: this.translations.tcAuxiliary,
        cm: this.translations.tcChurchMinistry,
        co: this.translations.tcOfficer,
        gc: this.translations.tcGideonCard,
        ff: this.translations.tcFaithFund,
        mb: this.translations.tcMembership,
        of: this.translations.tcOfficer,
        pw: this.translations.tcPrayerAndPersonalWitnessing,
        sd: this.translations.tcScriptureDistribution,
        st: this.translations.tcOfficer,
        xx: this.translations.tcProgram,
      }[code || 'xx']
    },
    async pageLoad() {
      await Promise.all([
        this.setLoadingStatus(true),
        this.loadMRPProgramSearchResults({
          lang: this.prefCulture,
          programname: this.mrpProgramInfo.itemName,
          term: this.mrpSearchText,
        }),
      ]).then(() => {
        this.setLoadingStatus(false)
      })
    },
    getIcon(type) {
      return (
        {
          doc: this.iconWord,
          pdf: this.iconPDF,
          xls: this.iconExcel,
          png: this.iconImage,
        }[type] || ''
      )
    },
    handleReset(evt) {
      this.selectedDateRangeStart = null
      this.selectedDateRangeEnd = null
    },
    async handleVideoClick(evt) {
      let categoryCode = null
      await this.loadMRPPrograms({ lang: 'en', landingpage: 'MRP_Landing' }).then(() => {
        try {
          categoryCode = this.MRPPrograms.find((mp) => mp.ProgramId === evt.TopicParentId).CategoryCode
        } catch (e) {
          next()
        }
      })
      await this.setMRPProgramId(evt.TopicParentId)
      await this.setMRPSelectedCategory(categoryCode)
      await this.setMRPSearchVideoId(evt.ItemID)
      await this.setMRPSelectedVideoId(evt.ItemID)
      if (!!evt.TopicId) {
        await this.setMRPSelectedTopicId(evt.TopicId)
        await this.loadMRPProgramInfo({
          lang: this.prefCulture,
          restype: 'topics',
          programid: evt.TopicParentId,
        })
        this.$router.push(`/resources/media-portal/program/topic-detail`)
      } else {
        this.$router.push(`/resources/media-portal/program/videos`)
      }
    },
    async handleTopicClick(evt) {
      await this.setMRPSelectedTopicId(evt.ItemID)
      this.$router.push({ name: 'mrp-topic-detail' })
    },
    async setDataTableFields() {
      this.dataTableFields[0].label = this.translations.tcType
      this.dataTableFields[1].label = this.translations.tcTitle
      return true
    },
  },
  computed: {
    ...mapGetters({
      mrpProgramInfo: 'mrp/mrpProgramInfo',
      MRPPrograms: 'mrp/mrpPrograms',
      mrpProgramSearchResults: 'mrp/mrpProgramSearchResults',
      mrpSearchText: 'mrp/mrpSearchText',
      mrpSelectedCategory: 'mrp/mrpSelectedCategory',
      mrpSelectedProgramId: 'mrp/mrpSelectedProgramId',
      prefCulture: 'user/userPreferredCulture',
    }),
    title() {
      return this.createTitle(this.mrpSelectedCategory)
    },
    filteredResults() {
      var filteredArr = this.mrpProgramSearchResults.results.filter((r) => {
        return true
      })
      if (this.selectedDateRangeStart || this.selectedDateRangeEnd) {
        const sDate = this.selectedDateRangeStart ? this.selectedDateRangeStart.replaceAll('-', '') : null
        const eDate = this.selectedDateRangeEnd ? this.selectedDateRangeEnd.replaceAll('-', '') : null
        filteredArr = filteredArr.filter((r) => {
          const cre = r.__Created.substring(0, 8)
          const upt = r.__Updated.substring(0, 8)
          return (
            ((sDate ? cre >= sDate : true) && (eDate ? cre <= eDate : true)) ||
            ((sDate ? upt >= sDate : true) && (eDate ? upt <= eDate : true))
          )
        })
      }
      if (this.selectedFilters.length > 0) {
        filteredArr = filteredArr.filter((r) => {
          var pos;
          if (r.Type == "View" || r.Type == "Topic") {
            pos = this.selectedFilters.indexOf("View")
          } else {
            pos = this.selectedFilters.indexOf(r.Type)
          }
          return pos >= 0
        })
      }
      return filteredArr
    },
    minimumDate() {
      const tday = new Date()
      const thirdYearAgo = tday.getFullYear() - 3
      tday.setFullYear(thirdYearAgo)
      return new Date(tday.getFullYear(), tday.getMonth() + 1, tday.getDate())
    },
  },
  async created() {
    if (!this.mrpSelectedProgramId) {
      this.$router.push('/resources/media-portal/')
    }
    await Promise.all([
      this.getViewTranslations(),
      this.getComponentTranslations('data-table-mrp', 'mrp-search-form'),
      this.pageLoad(),
    ]).then((results) => {
      const componentTranslations = results[1]
      this.$set(this.translations, 'components', componentTranslations)
      this.setDataTableFields()
    })
  },
  watch: {
    mrpSearchText() {
      this.pageLoad()
    },
    selectedDateRangeStart(newVal, oldVal) {
      if (oldVal !== newVal && !newVal) {
        // newVal is null bc they hit the reset button
        this.selectedDateRangeEnd = null
      }
    },
    selectedDateRangeEnd(newVal, oldVal) {
      if (oldVal !== newVal && !newVal) {
        // newVal is null bc they hit the reset button
        this.selectedDateRangeStart = null
      }
    },
  },
}
</script>

<style lang="scss" scoped>
/* eslint-disable */
@import '@/styles/global.scss';
@import '@/styles/bootstrap/b-button.scss';

.dp-container {
  display: flex;
  width: 60%;
  justify-content: flex-start;
  align-items: flex-start;

  @include breakpoint(sm) {
    width: 100%;
  }

  .dp-startend {
    width: 50%;
    display: inline-block;

    @include breakpoint(sm) {
      width: 100%;
    }
  }
}

.contact_date {
  width: 100%;

  @include breakpoint(sm) {
    width: 100%;
  }
}

.page {
  .page-body {
    @include breakpoint(sm) {
      margin-top: 3rem;
    }

    h4 {
      margin-bottom: 10px;
    }
  }
}

#filters-label {
  text-transform: uppercase;
  font-weight: bold;
}

.filter-row {
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;

  .filter-col {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    h5 {
      font-weight: bold;
      text-transform: uppercase;
    }

    .filters {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
  }
}

.result-row {
  .result-col {
    .result-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .result-detail {
        .result-title {
          font-weight: bold;
          color: #007bff;
          margin-bottom: 0;
        }
      }
    }

    div.filters {
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
    }
  }
}
</style>
